import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Card, Form } from "react-bootstrap";
import CustomsInput from "../../components/Inputs/CustomsInput";
import ActionButton from "../../components/butons/ActionButton";
import { object } from "yup";
import { validetionFunctions } from "../../helpers/validation";

function Component(props) {
	const [data, setData] = useState(null);
	const formMethods = useForm({
		defaultValues: { email: "" },
		resolver: yupResolver(
			object().shape({
				email: validetionFunctions.email,
			}),
		),
	});
	const { handleSubmit } = formMethods;
	const onSubmit = data => {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach((el, i) => {
					formData.append(key, el);
				});
			} else formData.append(key, value);
		});
		setData(data);
		// submit(formData, { method: "POST", action });
	};
	return (
		<div className="sign_container">
			<Card className="p-4 coustm_card d-flex gap-4 flex-column justify-content-center rounded">
				<h1 className="text-center fs-1">Forgot password?</h1>
				<FormProvider {...formMethods}>
					<Form
						noValidate={true}
						onSubmit={handleSubmit(onSubmit)}
						className="">
						{!data ? (
							<>
								<CustomsInput
									regName={"email"}
									type="text"
									required={true}
									label={"Email"}
									placeholder={"Name"}
								/>

								<ActionButton
									variant="outline-primary"
									buttonClassName="w-100 mt-4 "
									buttonName="Send"
								/>
							</>
						) : (
							<h5 className="text-center ">
								A verification code has been sent to your email, please check
								your email
							</h5>
						)}
					</Form>

					<div className="mb-0 text-center">
						<hr />
						<Link to="/" className="fw-bold link">
							Sign in
						</Link>
					</div>
				</FormProvider>
			</Card>
		</div>
	);
}

const ForgotPassword = Object.assign(Component, {});

export default ForgotPassword;
