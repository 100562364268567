import { useContext, useEffect } from "react";
import { useFormAction, useLoaderData } from "react-router-dom";
import ApiService from "../../helpers/api";
import { useAppSubmit } from "../../helpers/hooks";
import AlertService from "../../helpers/alertService";
import CardsBadges from "../../components/card/CardsBadges";
import AddButton from "../../components/butons/AddButton";
import { TimerContext } from "../../App";
import { LoadingContext } from "../../components/Root/Root";

const Component = () => {
	const submit = useAppSubmit();
	const action = useFormAction();
	const { setLoad, ids } = useContext(LoadingContext);
	const onSubmit = async id => {
		setLoad([...ids, id]);
		const formData = new FormData();
		formData.append("id", id);
		submit(formData, { method: "POST", action });
	};
	const data = useLoaderData();
	const { setIsUpdateTimer } = useContext(TimerContext);
	useEffect(() => {
		if (data) {
			setIsUpdateTimer(true);
		}
	}, [data, setIsUpdateTimer]);

	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`).then(res => {
			onSubmit(id);
		});
	return (
		<div>
			<AddButton buttonTitle="badge" to="form" pageTitle="Badges" />
			<CardsBadges show={show} data={data} />
		</div>
	);
};
const action = async ({ request }) => {
	try {
		const formData = await request.formData();
		const id = Object.fromEntries(formData).id;
		const data = await ApiService.delete_badge(id);
		if (data.status === 200) {
			AlertService.alert("success", "Data seved");
			return true;
		}
	} catch (error) {
		return error;
	}
};
const loader = async ({ request, params: { guid, id } }) => {
	try {
		const data = await ApiService.getByEntity();
		return data.data;
	} catch (error) {
		return error;
	}
};
const Badges = Object.assign(Component, { loader, action });
export default Badges;
