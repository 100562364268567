import React, { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import CustomSelect from "../../Inputs/CustomSelect";
import { Button, Modal, Table, Form, Spinner } from "react-bootstrap";
import ApiService from "../../../helpers/api";
import AlertService from "../../../helpers/alertService";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { TimerContext } from "../../../App";
import { LoadingContext } from "../../Root/Root";
import CustomsInput from "../../Inputs/CustomsInput";
import { validetionFunctions } from "../../../helpers/validation";

const QuestFragments = ({ data, id = "", fragmentsValue, setData }) => {
	const [open, setOpen] = useState(false);
	const [defaultValues, setDefaultValues] = useState({
		fragmentId: "",
		fragmentAmount: "",
	});
	const { setLoad, ids, setLoading, loading } = useContext(LoadingContext);
	const { setIsUpdateTimer } = useContext(TimerContext);
	const [isUpdate, setIsUpdate] = useState(false);
	const [shoewSelatc, setShowSelect] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(
			object().shape({
				fragmentId: string().required("This field is required"),
				fragmentAmount: validetionFunctions.count("Amout"),
			}),
		),
	});
	const {
		handleSubmit,
		setValue,
		reset,
		// formState: { errors },
	} = formMethods;
	const tableData = useMemo(() => {
		return data?.map(el => {
			const value = fragmentsValue?.find(item => item.value === el?.fragmentId);
			return {
				...el,
				...value,
			};
		});
	}, [data, fragmentsValue]);

	const selectValues = useMemo(() => {
		if (tableData.length && fragmentsValue.length) {
			fragmentsValue.filter(item => {
				const findItem = tableData.find(el => el.id === item.id);
				if (findItem) {
					return false;
				} else {
					return true;
				}
			});
		}
		return fragmentsValue;
	}, [fragmentsValue, tableData]);

	const onSubmit = async _data => {
		setIsLoading(true);
		if (isUpdate) {
			await ApiService.update_quest_fragment(_data)
				.then(res => {
					if (res.data) {
						AlertService.alert("success", "Data seved");
						const newData = data?.map(el => {
							if (el.id === res.data.id) {
								return res.data;
							}
							return el;
						});
						setData(prev => {
							return {
								...prev,
								questFragments: newData,
							};
						});
						setIsUpdateTimer(true);
					}
					onClose();
				})
				.catch(error => {
					Object.keys(error).forEach(key => {
						if (Array.isArray(error[key])) {
							error[key].forEach(el => {
								AlertService.alert("warning", el);
							});
						}
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			await ApiService.create_quest_fragment(_data)
				.then(res => {
					if (res.data) {
						AlertService.alert("success", "Data seved");
						setData(prev => {
							const newData = prev.questFragments
								? [...prev.questFragments, res.data]
								: [];
							return {
								...prev,
								questFragments: newData,
							};
						});
						setIsUpdateTimer(true);
						onClose();
					}
					return;
				})
				.catch(error => {
					Object.keys(error).forEach(key => {
						if (Array.isArray(error[key])) {
							error[key].forEach(el => {
								AlertService.alert("warning", el);
							});
						}
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		// submit(formData, { method: "POST", action });
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				setLoading(true);
				setLoad([...ids, id]);
				ApiService.delete_qust_fragment(id)
					.then(res => {
						if (res?.status === 200) {
							AlertService.alert("success", "Data seved");
							setData(prev => {
								const newData = prev.questFragments.filter(el => el.id !== id);
								return {
									...prev,
									questFragments: prev.questFragments ? newData : [],
								};
							});
							setIsUpdateTimer(true);
						} else {
							AlertService.alert("error", "Sorry, something went wrong!");
						}
					})
					.catch(error => {
						AlertService.alert("error", "Sorry, something went wrong!");
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(err => {});
	const onClose = () => {
		reset();
		setOpen(!open);
		setIsUpdate(false);
		setShowSelect(true);
		setDefaultValues({
			fragmentId: "",
		});
	};
	if (id) {
		return (
			<>
				<Modal
					show={open}
					onHide={() => {
						onClose();
					}}
					backdrop="static"
					centered
					keyboard={false}>
					<FormProvider {...formMethods}>
						<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
							<Modal.Header closeButton>
								<Modal.Title>{!id ? "Create" : "Update"}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<CustomSelect
									required={true}
									cb={setValue}
									disabled={!shoewSelatc && !!defaultValues?.label}
									defaultValues={defaultValues.fragmentId}
									options={selectValues}
									regName={`fragmentId`}
									label={"Fragments"}
								/>
								<CustomsInput
									regName={"fragmentAmount"}
									required={true}
									type="number"
									label={"Amount"}
									placeholder={"Rate"}
									isInteger={true}
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button
									disabled={isLoading}
									variant="secondary"
									onClick={onClose}>
									Close
								</Button>
								<Button disabled={isLoading} type="submit" variant="primary">
									{isLoading ? (
										<span className="d-flex align-items-center gap-2">
											<span>Loading...</span>
											<Spinner animation="border" role="status" size="sm" />
										</span>
									) : (
										"Save"
									)}
								</Button>
							</Modal.Footer>
						</Form>
					</FormProvider>
				</Modal>
				<div className="d-flex justify-content-between align-items-center p-2">
					<h5>Fragments</h5>
					<Button
						variant="light"
						onClick={() => {
							setOpen(true);
							setValue("questId", id);
							setIsUpdate(false);
						}}>
						<MdOutlineAddCircleOutline size={21} />
					</Button>
				</div>
				<div>
					<Table
						bordered
						responsive
						style={{
							backgroundColor: "#fff",
						}}
						className={`text-center mt-3`}>
						<thead>
							<tr>
								<th style={{ width: "10px" }} className="align-middle">
									#
								</th>
								<th className="align-middle">Name</th>
								<th className="align-middle">Amount</th>
								<th style={{ width: 100 }} className="align-middle">
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{Array.isArray(tableData) &&
								tableData.map((item, index) => {
									return (
										<React.Fragment key={item.id}>
											<tr>
												<td className="align-middle">{index + 1}</td>
												<td className="align-middle">{item.label}</td>
												<td className="align-middle">{item.fragmentAmount}</td>
												<td className="d-flex gap-1 w-full justify-content-around align-items-center button_container">
													<div
														className="button_svg sucsses_boredr"
														style={{
															borderColor: "#198754",
														}}
														onClick={() => {
															Object.entries(item).forEach(([key, value]) => {
																if (
																	key !== "label" &&
																	key !== "value" &&
																	key !== "fragment"
																) {
																	setValue(key, value);
																}
															});
															setValue("questId", id);
															setIsUpdate(true);
															setDefaultValues(item);
															setOpen(true);
															setShowSelect(false);
														}}>
														<AiOutlineEdit size={21} color="#198754" />
													</div>
													<div
														className="button_svg error_boredr"
														onClick={() => {
															if (ids.find(el => el === item.id && loading))
																return;
															show(item.id);
														}}>
														{ids.find(el => el === item.id) && loading ? (
															<Spinner
																animation="border"
																role="status"
																size="sm"
																style={{ color: "#dc3545" }}
															/>
														) : (
															<AiOutlineDelete size={21} color="#dc3545" />
														)}
													</div>
												</td>
											</tr>
										</React.Fragment>
									);
								})}
						</tbody>
					</Table>
				</div>
			</>
		);
	}
	return null;
};

export default QuestFragments;
