import ShopItemForm from "../../components/forms/ShopItemForm";
import ApiService from "../../helpers/api";
import FormLayout from "../../components/layout/FormLayout";
import AlertService from "../../helpers/alertService";

const Component = () => {
	return (
		<FormLayout pageTitle="Shop Form" backTo={"shop-item"}>
			<ShopItemForm />
		</FormLayout>
	);
};
const loader = async ({ request, params: { guid, itemId } }) => {
	try {
		const loaderData = await ApiService.get_categories();
		const resourceValue = await ApiService.getAllresource().then(res => {
			if (res.data && res.status === 200) {
				return res.data.map(el => ({ value: el.id, label: el.name }));
			}
			return [];
		});
		if (!loaderData.data && !resourceValue.length) {
			return null;
		}
		if (itemId) {
			const item = await ApiService.getShopItem(itemId);
			if (!item.data) {
				return null;
			}
			let infoCategory;
			if (item.data.categoryId) {
				infoCategory = await ApiService.get_category_id(item.data.categoryId);
			}
			return {
				loaderData: loaderData.data || [],
				item: item.data,
				infoCategory: infoCategory.data,
				resourceValue,
			};
		}
		return { loaderData: loaderData.data || [], resourceValue };
	} catch (error) {
		return error;
	}
};
const action = async ({ request, params: { itemId, guid } }) => {
	try {
		const requestData = await request.formData();

		if (itemId) {
			const data = await ApiService.updateShopItem(requestData);
			if (data.data && data.status === 200) {
				AlertService.alert("success", "Data seved");
				return "update";
			}
			return null;
		}
		const data = await ApiService.create_shopItem(requestData);
		if (data.status === 200) AlertService.alert("success", "Data seved");
		return data || [];
	} catch (error) {}
};
const ShopItemForms = Object.assign(Component, { action, loader });

export default ShopItemForms;
