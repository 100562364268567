import { useActionData, useLoaderData } from "react-router-dom";
import ApiService from "../../helpers/api";
import QuestFragments from "../../components/forms/quest/QuestFragments";
import QuestResources from "../../components/forms/quest/QuestResources";
import QuestForm from "../../components/forms/quest/QuestForm";
import { useContext, useEffect, useState } from "react";
import { toObject } from "../../helpers/helper";
import FormLayout from "../../components/layout/FormLayout";
import AlertService from "../../helpers/alertService";
import { TimerContext } from "../../App";

const Component = () => {
	const loaderData = useLoaderData();
	const actiondata = useActionData();
	const [data, setData] = useState(loaderData?.data);
	const { setIsUpdateTimer } = useContext(TimerContext);
	useEffect(() => {
		if (actiondata === "delete") {
			setIsUpdateTimer(true);
		}
	}, [actiondata, setIsUpdateTimer]);
	useEffect(() => {
		if (loaderData && loaderData.resourceValue) {
			setIsUpdateTimer(true);
		}
	}, [loaderData, setIsUpdateTimer]);
	useEffect(() => {
		if (loaderData?.data) {
			setData(loaderData.data);
		}
	}, [loaderData]);

	return (
		<FormLayout pageTitle="Quest" isShow={false} className="">
			<QuestForm data={data} setData={setData} />
			{data && data?.id && (
				<>
					<hr />
					<QuestResources
						id={data?.id}
						data={data?.questResources}
						resourceSelectValue={loaderData?.resourceValue}
						setData={setData}
					/>
					<QuestFragments
						id={data?.id}
						data={data?.questFragments}
						fragmentsValue={loaderData?.fragmentsValue}
						setData={setData}
					/>
				</>
			)}
		</FormLayout>
	);
};
const loader = async ({ request, params: { guid, id } }) => {
	try {
		const loaderData = await ApiService.get_quest();
		const resourceValue = await ApiService.getAllresource().then(res => {
			if (res.data && res.data.length) {
				return res.data
					.filter(el => !el.isFragmental)
					.map(el => ({ value: el.id, label: el.name }));
			}
			return [];
		});
		const fragmentsValue = await ApiService.get_all_fragments().then(res => {
			if (res.data && res.data.length) {
				return res.data.map(el => ({ value: el.id, label: el.name }));
			}
			return [];
		});
		const defaultFiles = await ApiService.getFiles(["Quest"]);
		let organiztuonFailes = [],
			defaultImg = [];
		if (defaultFiles && defaultFiles.data) {
			Object.entries(defaultFiles.data).forEach(([key, value]) => {
				switch (key) {
					case "DEFAULT/Quest":
						defaultImg = Object.entries(value).map(([key, value]) => ({
							value: key,
							label: value,
						}));
						return;
					case "Quest":
						organiztuonFailes = Object.entries(value).map(([key, value]) => ({
							value: key,
							label: value,
						}));
						return;

					default:
						return;
				}
			});
		}
		const _data = {
			data: loaderData?.data,
			fragmentsValue,
			resourceValue,
			defaultImg: defaultImg ? defaultImg : [],
			organiztuonFailes: organiztuonFailes ? organiztuonFailes : [],
		};
		return _data;
	} catch (error) {
		return error;
	}
};

const action = async ({ request, params: { itemId, guid } }) => {
	try {
		const requestData = await request.formData();
		const obj = toObject(requestData);
		if (obj.method === "delete") {
			const data = await ApiService.delete_quest(obj.id);
			if (data.status === 200) {
				AlertService.alert("success", "Data seved");
				return "delete";
			}
			return null;
		}
		if (obj?.id && obj.method !== "delete") {
			const data = await ApiService.update_quest(requestData);
			if (data.status === 200) AlertService.alert("success", "Data seved");
			return data;
		}
		const data = await ApiService.create_quest(requestData);
		if (data.status === 200) AlertService.alert("success", "Data seved");
		return data;
	} catch (error) {
		throw new Error(error);
	}
};
const Quest = Object.assign(Component, { loader, action });
export default Quest;
