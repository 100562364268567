import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import AddButton from "../../components/butons/AddButton";
import NoData from "../../components/noData/NoData";
import AlertService from "../../helpers/alertService";
import ApiService from "../../helpers/api";
import { FormProvider, useForm } from "react-hook-form";
import CustomsInput from "../../components/Inputs/CustomsInput";
import { GoCopy } from "react-icons/go";
import { useActionData, useFormAction, useLoaderData } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useAppSubmit } from "../../helpers/hooks";
import { object } from "yup";
import { validetionFunctions } from "../../helpers/validation";
import { yupResolver } from "@hookform/resolvers/yup";

function Component(props) {
	const submit = useAppSubmit(),
		action = useFormAction(),
		actiondata = useActionData();
	const loaderData = useLoaderData();
	const [load, setLoad] = useState(false);
	let [data, setData] = useState(loaderData);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	useEffect(() => {
		if (actiondata) {
			setData(prev => {
				return prev.filter(el => el.id !== +actiondata);
			});
		}
	}, [actiondata]);
	const formMethods = useForm({
		defaultValues: { name: "", description: "" },
		resolver: yupResolver(
			object().shape({
				name: validetionFunctions.name("Name"),
				description: validetionFunctions.description,
			}),
		),
	});
	const { handleSubmit, setValue, reset } = formMethods;
	const onSubmit = async _data => {
		setLoad(true);
		if (isUpdate) {
			await ApiService.entity_type_update(_data)
				.then(res => {
					if (res.success && res.status === 200) {
						AlertService.alert("success", "Data seved");
						const newData = data.map(el => {
							if (el.id === _data.id) {
								return _data;
							}
							return el;
						});
						setData(newData);
					}
					onClose();
				})
				.catch(() => {})
				.finally(() => {
					setLoad(false);
				});
		} else {
			await ApiService.entity_type_create(_data)
				.then(res => {
					if (res.status === 200 && res.data) {
						AlertService.alert("success", "Data seved");
						const newData = [...data, res.data];
						setData(prev => {
							return newData;
						});
					}
					onClose();
				})
				.catch(() => {})
				.finally(() => {
					setLoad(false);
				});
		}
	};

	const onClose = () => {
		reset();
		setOpen(!open);
	};
	const onSubmitDelete = async id => {
		const formData = new FormData();
		formData.append("id", id);
		submit(formData, { method: "POST", action });
	};
	const copyToClipBoard = async copyMe => {
		try {
			await navigator.clipboard.writeText(copyMe);
			AlertService.alert("success", "ID copied to clipboard ");
		} catch (err) {
			AlertService.alert("warning", "Sorry, something went wrong!");
		}
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				onSubmitDelete(id);
			})
			.catch(() => {});
	return (
		<div>
			<Modal
				show={open}
				onHide={onClose}
				backdrop="static"
				centered
				keyboard={false}>
				<FormProvider {...formMethods}>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Modal.Header closeButton>
							<Modal.Title>{!isUpdate ? "Create" : "Update"}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<CustomsInput
								regName={"name"}
								required={true}
								label={"Name"}
								placeholder={""}
							/>
							<CustomsInput
								regName={"description"}
								required={true}
								type="textarea"
								label={"Description"}
								placeholder={""}
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button disabled={load} variant="secondary" onClick={onClose}>
								Close
							</Button>
							<Button disabled={load} type="submit" variant="primary">
								{load ? (
									<span className="d-flex align-items-center gap-2">
										<span>Loading...</span>
										<Spinner animation="border" role="status" size="sm" />
									</span>
								) : (
									"Save"
								)}
							</Button>
						</Modal.Footer>
					</Form>
				</FormProvider>
			</Modal>
			<AddButton
				pageTitle="Entity"
				buttonTitle="entity"
				action={() => {
					setOpen(true);
				}}
			/>
			{Array.isArray(data) && data.length ? (
				<Table bordered responsive className={` mt-3 `}>
					<thead>
						<tr>
							<th style={{ width: "10px" }} className="align-middle">
								#
							</th>
							<th className="align-middle">Name</th>
							<th className="align-middle">Description</th>
							<th style={{ width: 100 }} className="align-middle">
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((item, index) => {
							return (
								<React.Fragment key={item.id}>
									<tr>
										<td>{item.id}</td>
										<td>{item.name}</td>
										<td>{item.description}</td>
										<td className="d-flex gap-2 justify-content-evenly button_container">
											<div
												className="button_svg clipboard_copy_border"
												onClick={() => {
													copyToClipBoard(item.id);
												}}>
												<GoCopy size={21} color="#0a58ca" />
											</div>
											<div
												className="button_svg sucsses_boredr"
												style={{
													borderColor: "#198754",
												}}
												onClick={() => {
													setOpen(true);
													setValue("name", item.name);
													setValue("description", item.description);
													setValue("id", item.id);
													setIsUpdate(true);
												}}>
												<AiOutlineEdit size={21} color="#198754" />
											</div>
											<div
												className="button_svg error_boredr"
												onClick={() => {
													show(item.id);
												}}>
												<AiOutlineDelete size={21} color="#dc3545" />
											</div>
										</td>
									</tr>
								</React.Fragment>
							);
						})}
					</tbody>
				</Table>
			) : (
				<NoData />
			)}
		</div>
	);
}
const action = async ({ request, params }) => {
	try {
		const formData = await request.formData();
		const id = Object.fromEntries(formData).id;
		const data = await ApiService.entity_type_deletel(id);
		if (data.status === 200) AlertService.alert("success", "Data seved");
		return id;
	} catch (error) {
		return error;
	}
};
const loader = async ({ request, params: { guid, id } }) => {
	try {
		const loaderData = await ApiService.entity_type_getAll();
		return loaderData;
	} catch (error) {
		return error;
	}
};
const Entity = Object.assign(Component, { loader, action });
export default Entity;
