import React, { useContext, useEffect, useState } from "react";
import { useAppSubmit } from "../../helpers/hooks";
import {
	useActionData,
	useFormAction,
	useLoaderData,
	useNavigate,
	useParams,
} from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import CustomsInput from "../../components/Inputs/CustomsInput";
import ApiService from "../../helpers/api";
import Select from "react-select";
import FormLayout from "../../components/layout/FormLayout";
import ActionButton from "../../components/butons/ActionButton";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AlertService from "../../helpers/alertService";
import { toObject } from "../../helpers/helper";
import { Col, Form, Row } from "react-bootstrap";
import { TimerContext } from "../../App";
import { LoadingContext } from "../../components/Root/Root";
import DeleteButton from "../../components/butons/DeleteButton";

function Component() {
	const { setLoading } = useContext(LoadingContext);
	const { setIsUpdateTimer } = useContext(TimerContext);
	const { loaderData, item, infoCategory } = useLoaderData();
	const { guid, itemId } = useParams();
	const [data, setData] = useState([loaderData]);
	const [selectValue, setSelectValue] = useState([]);
	const [selectOptions, setSelectOptions] = useState([]);
	const submit = useAppSubmit(),
		action = useFormAction();
	const actiondata = useActionData();
	const navigate = useNavigate();
	const formMethods = useForm({
		defaultValues: {
			name: item?.data?.name || "",
			parentId: item?.data?.parentId || "",
			description: item?.data?.description || "",
		},
		resolver: yupResolver(
			object().shape({
				name: string()
					.required("This field is required")
					.matches(/^(|.{1,200})$/, "Between 5-1000 characters"),
				description: string().matches(
					/^(|.{1,2000})$/,
					"Between 1-1000 characters",
				),
			}),
		),
	});
	const { handleSubmit, setValue } = formMethods;

	useEffect(() => {
		if (loaderData) {
			setIsUpdateTimer(true);
		}
	}, [loaderData, setIsUpdateTimer]);
	useEffect(() => {
		if (actiondata?.data) {
			navigate(`/category/${guid}`);
		} else if (actiondata === "update") {
			navigate(`/category/${guid}`);
		}
	}, [actiondata, guid, navigate]);

	useEffect(() => {
		if (loaderData && Array.isArray(loaderData.data)) {
			const optionsValue = loaderData?.data.map(el => ({
				value: el.id,
				label: el.name,
			}));
			optionsValue.unshift({ label: "As Parent", value: null });
			setSelectOptions(prev => {
				return [optionsValue];
			});
		}
	}, [loaderData]);

	const onChange = async (e, index) => {
		let newSelectData = selectOptions.slice(0, index + 1);
		let newData = data.slice(0, index + 1);
		let newValue = selectValue.slice(0, index);
		if (!e || (e && !e.value)) {
			setSelectOptions(newSelectData);
			setData(newData);
			setSelectValue([]);
			setValue("parentId", "");
			return;
		}
		setSelectValue(prev => [...newValue, { ...e, index }]);
		await ApiService.get_categories(e.value)
			.then(res => {
				let optionsValue = [];
				if (res.data && res.data.length) {
					setData([...data, res.data]);
					optionsValue = res.data.map(el => ({
						value: el.id,
						label: el.name,
					}));
				} else setData(newData);
				if (optionsValue.length) {
					setSelectOptions([...newSelectData, optionsValue]);
				} else setSelectOptions(newSelectData);
			})
			.catch(err => {
				setSelectOptions(newSelectData);
			});
		setValue("parentId", e.value, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	const onSubmit = data => {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => {
			if (value) {
				formData.append(key, value);
			}
		});
		if (itemId) {
			formData.append("id", itemId);
		}
		submit(formData, { method: "POST", action });
	};

	const show = id => {
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				setLoading(true);
				ApiService.deleteShopItem(id)
					.then(response => {
						if (response.status === 200) {
							AlertService.alert("success", "Data seved");
							navigate(`/category/${guid}`);
							return true;
						}
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(err => {});
	};

	return (
		<FormLayout pageTitle="Category" backTo={"category"}>
			<FormProvider {...formMethods}>
				<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={6} className="mb-3 ">
							{!!itemId && !!infoCategory?.data && (
								<h5>Selected category {infoCategory?.data?.name}</h5>
							)}
							{!itemId && selectOptions && !!selectOptions.length && (
								<div className="form-group d-flex mb-3 flex-column gap-2">
									<label className="mb-1">
										{selectValue.length
											? selectValue[selectValue.length - 1].label
											: "Choose category"}
									</label>
									{selectOptions.map((item, i) => (
										<Select
											className=""
											options={item}
											styles={{
												control: (provided, state) => ({
													...provided,
													borderColor: state.isFocused
														? "86b7fe"
														: state.selectProps.error
														? "red"
														: "gray",
													boxShadow: state.isFocused
														? "0 0 0 0.25rem rgba(13,110,253,.25)"
														: "",
												}),
											}}
											defaultValue={selectValue[i]}
											onChange={e => {
												onChange(e, i);
											}}
										/>
									))}
								</div>
							)}
						</Col>
						<Col md={6}>
							<CustomsInput
								regName={"name"}
								type="text"
								required={true}
								label={"Name"}
								placeholder={"Category name"}
							/>
						</Col>
					</Row>
					<CustomsInput
						regName={"description"}
						type="textarea"
						label={"Description"}
						placeholder={"Description"}
					/>
					<div className="w-100 d-flex justify-content-end gap-2">
						{itemId && (
							<DeleteButton
								type="button"
								buttonName="Delete"
								variant="outline-danger"
								cb={() => {
									show(itemId);
								}}
							/>
						)}
						<ActionButton buttonName="Save" />
					</div>
				</Form>
			</FormProvider>
		</FormLayout>
	);
}
const loader = async ({ request, params: { guid, itemId } }) => {
	try {
		const loaderData = await ApiService.get_categories();

		if (itemId) {
			const item = await ApiService.get_category_id(itemId);
			let infoCategory;
			if (item.data.parentId)
				infoCategory = await ApiService.get_category_id(item.data.parentId);
			return { loaderData, item, infoCategory };
		}
		return { loaderData };
	} catch (error) {
		return error;
	}
};
const action = async ({ request, params: { itemId, guid } }) => {
	try {
		const requestData = await request.formData();
		const obj = toObject(requestData);
		if (itemId) {
			const data = await ApiService.update_category(obj);
			if (data.data && data.status === 200) {
				AlertService.alert("success", "Data seved");
				return "update";
			}
			return null;
		}
		const data = await ApiService.create_category(obj);
		if (data.status === 200) {
			AlertService.alert("success", "Data seved");
			return data || [];
		}
		return [];
	} catch (error) {}
};
const CategoryForm = Object.assign(Component, { loader, action });
export default CategoryForm;
