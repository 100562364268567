import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import NoData from "../noData/NoData";
import { FaLink } from "react-icons/fa";
import { LoadingContext } from "../Root/Root";

function CardsResouerce({ data = [], show }) {
	const [modalData, setModalData] = useState(null);
	const { load, ids } = useContext(LoadingContext);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const handleClose = () => {
		setOpen(false);
		setModalData(null);
	};
	return (
		<>
			<Modal
				show={open}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header closeButton>
					<Modal.Title>{modalData?.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex gap-3 flex-wrap align-items-stretch p-2">
						{modalData?.data?.length ? (
							modalData?.data?.map(item => (
								<div
									className="d-flex p-2 flex-column gap-3 align-items-center justify-content-center border border-dark-subtle border-opacity-50 border-1"
									key={item.id}>
									<img
										className="box-image"
										style={{
											height: "150px ",
											width: "150px",
										}}
										src={item.imagePath}
										alt={""}
									/>
									<h5 className="">{item.name}</h5>
								</div>
							))
						) : (
							<div>
								<h5 className="d-flex gap-2 align-items-center">
									You don't have any fragments created yet
									<Link
										style={{ textDecoration: "underline" }}
										to={`form/${modalData?.id}`}>
										{/* <span
											style={{
												fontWeight: 400,
												marginRight: 5,
												color: "#0d6efd",
											}}>
											Add
										</span> */}
										<FaLink
											style={{
												width: 15,
												height: 15,
											}}
											color="#0d6efd"
										/>
									</Link>
								</h5>
							</div>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Row className="align-self-stretch">
				{data && data.length ? (
					data.map((item, index) => {
						return (
							<Col key={index} md={4} lg={3} className="p-2">
								<Card
									className="h-100 p-1"
									title={item.isDefault ? `Main resource` : null}>
									<div
										className="w-100 pt-2"
										// src={item.imagePath}
										style={{
											backgroundImage: `url(${item.imagePath})`,
											height: "200px",
											objectFit: "contain",
											backgroundPosition: "center",
											backgroundSize: "contain",
											backgroundRepeat: "no-repeat",
										}}
										alt="/"
									/>
									{/* <div
										className="w-100"
										style={{
											backgroundImage: `url(${item.imagePath})`,
											height: "200px",
											backgroundPosition: "center",
											backgroundSize: "contain",
											backgroundRepeat: "no-repeat",
										}}
									/> */}
									<Card.Body className="d-flex flex-column justify-content-end">
										<div>
											<h4
												className="mb-3 text-center max-line-1"
												title={item.name}>
												{item.name}
											</h4>
											{item.description && (
												<p title={item.description} className="max-line-3">
													{item.description}
												</p>
											)}
											<hr />
											{item.rate && (
												<p className="d-flex justify-content-between mb-2">
													<b>Rate</b>
													<b>{item.rate}</b>
												</p>
											)}

											{/* <p className="d-flex justify-content-between mb-2">
												<b>Is Default</b>
												{item.isDefault ? (
													<FaCheck color="#24ad05" fontSize={16} />
												) : (
													<TiDeleteOutline color="#ad0505" fontSize={20} />
												)}
											</p>
											<p className="d-flex justify-content-between mb-2">
												<b>Is Fragmental</b>
												{item.isFragmental ? (
													<FaCheck color="#24ad05" fontSize={16} />
												) : (
													<TiDeleteOutline color="#ad0505" fontSize={20} />
												)}
											</p> */}
										</div>

										<div className="mt-2">
											{item.isFragmental && (
												<Button
													variant="outline-primary"
													className="w-100 mb-2"
													onClick={() => {
														setOpen(true);
														setModalData({
															name: item.name,
															data: item.fragments,
															id: item.id,
														});
													}}>
													Show Fragments
												</Button>
											)}

											<div className="d-flex gap-1">
												<Button
													variant="outline-success"
													className="w-100"
													onClick={() => {
														navigate(`form/${item.id}`);
													}}>
													Edit
												</Button>
												{!item.isDefault && (
													<Button
														variant="outline-danger"
														className="w-100"
														disabled={item.isDefault}
														onClick={() => {
															if (item.isDefault) {
																return false;
															}
															if (ids.find(el => el === item.id) && load)
																return;
															show(item.id);
														}}>
														{ids.find(el => el === item.id) && load ? (
															<Spinner
																animation="border"
																role="status"
																size="sm"
																style={{ color: "#dc3545" }}
															/>
														) : (
															"Delete"
														)}
													</Button>
												)}
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						);
					})
				) : (
					<NoData />
				)}
			</Row>
		</>
	);
}

export default CardsResouerce;
