import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import Root from "../components/Root/Root";
import ResouercesForms from "./resouerces/ResouercesForms";
import Resouerces from "./resouerces/Resouerces";
import LeaderbordForm from "./leaderbord/LeaderbordForm";
import ShopItem from "./shop-item/ShopItem";
import ShopItemForms from "./shop-item/ShopItemForms";
import Leaderbord from "./leaderbord/Leaderbord";
import Badges from "./badges/Badgs";
import Error from "./error/Error";
import Quest from "./quest/Quest";
import BadgeForms from "./badges/BadgeForms";
import Category from "./category/Category";
import CategoryForm from "./category/CategoryForm";
import SignIn from "./sign/SignIn";
import SignUp from "./sign/SignUp";
import ForgotPassword from "./sign/ForgotPassword";
import AuthRoot from "../components/Root/AuthRoot";
import Entity from "./entity/Entity";
import AdminRoot from "../components/Root/AdminRoot";

const adminRouter = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<AdminRoot />} errorElement={<Error />}>
			<Route index={true} element={<div>aaaa</div>} />
			<Route path="files" element={<>files</>} />
		</Route>,
	),
);

const authRouter = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<AuthRoot />} errorElement={<Error />}>
			<Route index={true} element={<>TODO</>} />
			<Route
				path="entity"
				element={<Entity />}
				loader={Entity.loader}
				action={Entity.action}
			/>
		</Route>,
	),
);

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Root />} errorElement={<Error />}>
			<Route index element={<SignIn />} action={SignIn.action} />
			<Route path="sing-up" element={<SignUp />} action={SignUp.action} />
			<Route path="forgot-password" element={<ForgotPassword />} />
			<Route path="reset-password/:guid" element={<></>} />
			<Route path="resources/:guid">
				<Route
					index
					element={<Resouerces />}
					loader={Resouerces.loader}
					action={Resouerces.action}
				/>
				<Route path="form">
					<Route
						index
						element={<ResouercesForms />}
						loader={ResouercesForms.loader}
						action={ResouercesForms.action}
					/>
					{/* <Route
						path="?search"
						element={<ResouercesForms />}
						loader={ResouercesForms.loader}
						action={ResouercesForms.action}
					/> */}
					<Route
						path=":itemId"
						element={<ResouercesForms />}
						loader={ResouercesForms.loader}
						action={ResouercesForms.action}
					/>
				</Route>
			</Route>
			<Route path="quest/:guid">
				<Route
					index
					element={<Quest />}
					loader={Quest.loader}
					action={Quest.action}
				/>
			</Route>
			<Route path="badges/:guid">
				<Route
					index
					element={<Badges />}
					loader={Badges.loader}
					action={Badges.action}
				/>
				<Route path="form">
					<Route
						index
						element={<BadgeForms />}
						action={BadgeForms.action}
						loader={BadgeForms.loader}
					/>
					<Route
						path=":itemId"
						element={<BadgeForms />}
						action={BadgeForms.action}
						loader={BadgeForms.loader}
					/>
				</Route>
			</Route>
			<Route path="leaderboard/:guid">
				<Route
					index
					element={<Leaderbord />}
					loader={Leaderbord.loader}
					action={Leaderbord.action}
				/>
				<Route path="form">
					<Route
						index
						element={<LeaderbordForm />}
						loader={LeaderbordForm.loader}
						action={LeaderbordForm.action}
					/>
					<Route
						path=":itemId"
						element={<LeaderbordForm />}
						action={LeaderbordForm.action}
						loader={LeaderbordForm.loader}
					/>
				</Route>
			</Route>
			<Route path="shop-item/:guid">
				<Route
					index
					element={<ShopItem />}
					loader={ShopItem.loader}
					action={ShopItem.action}
				/>
				<Route path="form">
					<Route
						index
						element={<ShopItemForms />}
						loader={ShopItemForms.loader}
						action={ShopItemForms.action}
					/>
					<Route
						path=":itemId"
						element={<ShopItemForms />}
						loader={ShopItemForms.loader}
						action={ShopItemForms.action}
					/>
				</Route>
			</Route>
			<Route path="category/:guid">
				<Route
					index
					element={<Category />}
					loader={Category.loader}
					action={Category.action}
				/>
				<Route path="form">
					<Route
						index
						element={<CategoryForm />}
						loader={CategoryForm.loader}
						action={CategoryForm.action}
					/>
					<Route
						path=":itemId"
						element={<CategoryForm />}
						loader={CategoryForm.loader}
						action={CategoryForm.action}
					/>
				</Route>
			</Route>
			{/* <Route path="template" element={<Navbar />} /> */}
		</Route>,
	),
);

export { authRouter, router, adminRouter };
