import { Col, Row } from "react-bootstrap";

import { useActionData, useFormAction, useLoaderData } from "react-router-dom";
import ApiService from "../../helpers/api";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { useAppSubmit } from "../../helpers/hooks";
import AlertService from "../../helpers/alertService";
import CardsShop from "../../components/card/CardsShop";
import AddButton from "../../components/butons/AddButton";
import { TimerContext } from "../../App";
import { LoadingContext } from "../../components/Root/Root";

const randomID = () => Math.random().toString();

const Component = () => {
	const loaderData = useLoaderData();
	const [data, setData] = useState([loaderData]);
	const [value, setValue] = useState([]);
	const [selectOptions, setSelectOptions] = useState([]);
	const [showData, setShowData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { setLoad, ids } = useContext(LoadingContext);
	const submit = useAppSubmit(),
		action = useFormAction(),
		actiondata = useActionData();
	const onSubmit = id => {
		setLoad([...ids, id]);
		const formData = new FormData();
		formData.append("id", id);
		submit(formData, { method: "POST", action });
	};
	const { setIsUpdateTimer } = useContext(TimerContext);

	useEffect(() => {
		if (loaderData) {
			setIsUpdateTimer(true);
		}
	}, [loaderData, setIsUpdateTimer]);
	useEffect(() => {
		if (Array.isArray(loaderData)) {
			const optionsValue = loaderData.map(el => ({
				value: el.id,
				label: el.name,
			}));
			setSelectOptions(prev => {
				return [optionsValue];
			});
		}
	}, [loaderData]);
	useEffect(() => {
		if (actiondata && value.length) {
			(async () => {
				await ApiService.get_category_id(value[value.length - 1].value)
					.then(res => {
						if (res.data) {
							setShowData(res.data.shopItems);
						} else {
							setShowData([]);
						}
					})
					.catch(error => {});
			})();
		}
	}, [actiondata, value]);

	const onChange = async (e, index) => {
		setIsLoading(true);
		let newSelectData = selectOptions.slice(0, index + 1);
		let newData = data.slice(0, index + 1);
		let newValue = value.slice(0, index);
		setValue(prev => [...newValue, { ...e, index }]);
		await ApiService.get_categories(e.value)
			.then(res => {
				let optionsValue = [];
				if (res.data) {
					setData([...data, res.data]);
					optionsValue = res.data.map(el => ({
						value: el.id,
						label: el.name,
					}));
				} else setData(newData);
				if (optionsValue.length) {
					setSelectOptions([...newSelectData, optionsValue]);
				}
				setIsUpdateTimer(true);
			})
			.catch(err => {
				setSelectOptions(newSelectData);
			});

		await ApiService.get_category_id(e.value)
			.then(res => {
				if (res) {
					setShowData(res.data.shopItems);
					setIsUpdateTimer(true);
				} else {
					setShowData([]);
				}
			})
			.catch(error => {})
			.finally(() => {
				setIsLoading(false);
			});
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				onSubmit(id);
			})
			.catch(() => {});
	return (
		<>
			<AddButton
				pageTitle="Shop"
				buttonTitle="shop item"
				to={"form"}
				isCategory={true}
			/>
			<Row className="">
				<Col>
					{selectOptions.map((item, i) => (
						<div className="form-group mb-3" key={randomID()}>
							<label className="mb-2">Choose category</label>
							<Select
								classNamePrefix="filter"
								className=""
								options={item}
								styles={{
									control: (provided, state) => ({
										...provided,
										borderColor: state.isFocused
											? "86b7fe"
											: state.selectProps.error
											? "red"
											: "gray",
										boxShadow: state.isFocused
											? "0 0 0 0.25rem rgba(13,110,253,.25)"
											: "",
									}),
								}}
								defaultValue={value[i]}
								onChange={e => {
									onChange(e, i);
								}}
							/>
							{value[value.length - 1]?.index === i && isLoading && (
								<div
									className="loader-line"
									style={{
										position: "sticky",
									}}></div>
							)}
						</div>
					))}
				</Col>
				<Col lg={8} md={12}>
					<CardsShop data={showData} show={show} />
				</Col>
			</Row>
		</>
	);
};
const action = async ({ request, params }) => {
	try {
		const formData = await request.formData();
		const id = Object.fromEntries(formData).id;
		const data = await ApiService.deleteShopItem(id);
		if (data.status === 200 && !data.data) {
			AlertService.alert("success", "Data seved");
			return id;
		}
		return null;
	} catch (error) {
		return error;
	}
};
const loader = async ({ request, params: { guid, id } }) => {
	try {
		const category = await ApiService.get_categories();
		if (category && category.data) return category.data;
	} catch (error) {
		return error;
	}
};

const ShopItem = Object.assign(Component, { loader, action });
export default ShopItem;
