import React, { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useNavigation } from "react-router-dom";
import { LoadingContext } from "../Root/Root";

export default function ActionButton({
	buttonName = "",
	type = "submit",
	buttonClassName = "",
	variant = "primary",
	cb,
}) {
	const { loading, ids } = useContext(LoadingContext);
	const navigation = useNavigation();
	let isLoading = navigation?.state === "submitting";

	return (
		<div
			className={`d-flex ${
				false ? "justify-content-between" : "justify-content-end"
			} align-items-center flex-wrap-reverse gap-3  mt-2`}>
			<Button
				disabled={isLoading || loading}
				type={type}
				className={`px-4 ${buttonClassName} d-flex justify-content-center`}
				onClick={() => {
					if (cb) {
						return cb();
					}
				}}
				variant={variant}>
				{isLoading && !ids.length ? (
					<span className="d-flex align-items-center gap-2">
						<span>Loading...</span>
						<Spinner animation="border" role="status" size="sm" />
					</span>
				) : (
					buttonName
				)}
			</Button>
		</div>
	);
}
