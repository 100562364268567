const Component = () => {
	return (
		<>
			<div className="body">
				<div className="mars"></div>
				{/* <img src="https://assets.codepen.io/1538474/404.svg" className="logo-404" /> */}
				<img
					src="https://assets.codepen.io/1538474/meteor.svg"
					className="meteor"
					alt="meteor"
				/>
				<p className="title">Oh no!!</p>
				<p className="subtitle">
					Oh, this page somehow can't be found. We are working on this. Come
					back soon.
				</p>
				<div align="center">
					<div
						className="btn-back"
						onClick={() => {
							window.location.reload(false);
						}}>
						Back to previous page
					</div>
				</div>
				<img
					src="https://assets.codepen.io/1538474/astronaut.svg"
					className="astronaut"
					alt="astronaut"
				/>
				<img
					src="https://assets.codepen.io/1538474/spaceship.svg"
					className="spaceship"
					alt="spaceship"
				/>
			</div>
		</>
	);
};

const Error = Object.assign(Component, {});
export default Error;
