import React, { useContext, useMemo, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { Form, useParams } from "react-router-dom";
import CustomSelect from "../../Inputs/CustomSelect";
import CustomsInput from "../../Inputs/CustomsInput";
import ApiService from "../../../helpers/api";
import AlertService from "../../../helpers/alertService";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import { validetionFunctions } from "../../../helpers/validation";
import { TimerContext } from "../../../App";
import { LoadingContext } from "../../Root/Root";

const BadgeResources = ({ data = [], setData, resourceSelectValue }) => {
	const { itemId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const { setIsUpdateTimer } = useContext(TimerContext);
	const { setLoad, ids, setLoading, loading } = useContext(LoadingContext);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isShowSelect, setIsShowSelect] = useState(true);
	const [defaultValues, setDefaultValues] = useState({
		badgeId: itemId,
		resourceId: "",
		resourceAmount: "",
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(
			object().shape({
				resourceId: validetionFunctions.select,
				resourceAmount: validetionFunctions.count("Amount"),
			}),
		),
	});
	const { handleSubmit, setValue, reset } = formMethods;
	const tableData = useMemo(() => {
		return data?.map(el => {
			const value = resourceSelectValue.find(
				item => item.value === el?.resourceId,
			);
			return {
				...el,
				...value,
			};
		});
	}, [data, resourceSelectValue]);

	const onSubmit = async _data => {
		setIsLoading(true);

		if (isUpdate) {
			await ApiService.update_badge_resource(_data)
				.then(res => {
					if (res.data) {
						AlertService.alert("success", "Data seved");
						setData(prev => {
							const newData = data?.map(el => {
								if (el.id === res.data.id) {
									return res.data;
								}
								return el;
							});
							return {
								...prev,
								badgeResources: newData,
							};
						});
					}
					setIsUpdateTimer(true);
					onClose();
				})
				.catch(error => {
					Object.keys(error).forEach(key => {
						if (Array.isArray(error[key])) {
							error[key].forEach(el => {
								AlertService.alert("warning", el);
							});
						}
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
			return;
		}
		await ApiService.create_badge_resource(_data)
			.then(res => {
				if (res.data) {
					AlertService.alert("success", "Data seved");
					setData(prev => {
						const newData = [...data, res.data];
						return {
							...prev,
							badgeResources: newData,
						};
					});
				}
				setIsUpdateTimer(true);
				onClose();
			})
			.catch(error => {
				Object.keys(error).forEach(key => {
					if (Array.isArray(error[key])) {
						error[key].forEach(el => {
							AlertService.alert("warning", el);
						});
					}
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	const onClose = () => {
		setOpen(!open);
		reset({ badgeId: itemId, resourceId: "", resourceAmount: "" });
		setIsUpdate(false);
		setDefaultValues({
			badgeId: itemId,
			resourceId: "",
			resourceAmount: "",
		});
		setIsShowSelect(true);
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				setLoad([...ids, id]);
				setLoading(true);
				ApiService.delete_badge_resource(id)
					.then(res => {
						AlertService.alert("success", "Data seved");
						if (res.status === 200) {
							setData(prev => {
								let newData = data?.filter(el => el.id !== id);
								return {
									...prev,
									badgeResources: newData,
								};
							});
							setIsUpdateTimer(true);
						}
					})
					.catch(error => {
						AlertService.alert("error", "Sorry, something went wrong!");
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(() => {});
	if (itemId) {
		return (
			<>
				<Modal
					show={open}
					onHide={onClose}
					backdrop="static"
					centered
					keyboard={false}>
					<FormProvider {...formMethods}>
						<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
							<Modal.Header closeButton>
								<Modal.Title>{!isUpdate ? "Create" : "Update"}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<CustomSelect
									cb={setValue}
									options={resourceSelectValue}
									regName={`resourceId`}
									disabled={!!defaultValues.label && !isShowSelect}
									required={true}
									defaultValues={defaultValues}
									label={"Resource"}
								/>
								<CustomsInput
									regName={"resourceAmount"}
									type="number"
									required={true}
									label={"Amount"}
									placeholder={"Amount"}
									isInteger={true}
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button
									disabled={isLoading}
									variant="secondary"
									onClick={onClose}>
									Close
								</Button>
								<Button disabled={isLoading} type="submit" variant="primary">
									{isLoading ? (
										<span className="d-flex align-items-center gap-2">
											<span>Loading...</span>
											<Spinner animation="border" role="status" size="sm" />
										</span>
									) : (
										"Save"
									)}
								</Button>
							</Modal.Footer>
						</Form>
					</FormProvider>
				</Modal>
				<div className="d-flex justify-content-between align-items-center p-2">
					<h5>Resources</h5>
					<Button
						variant="light"
						onClick={() => {
							setOpen(true);
						}}>
						<MdOutlineAddCircleOutline size={21} />
					</Button>
				</div>
				<div>
					<Table
						bordered
						responsive
						style={{
							backgroundColor: "#fff",
						}}
						className={`text-center mt-3`}>
						<thead>
							<tr>
								<th style={{ width: "10px" }}>#</th>
								<th className="align-middle">Name</th>
								<th className="align-middle">Amount</th>
								<th style={{ width: 100 }} className="align-middle">
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{tableData?.map((item, index) => {
								return (
									<React.Fragment key={item.label}>
										<tr>
											<td className="align-middle">{index + 1}</td>
											<td className="align-middle">{item.label}</td>
											<td className="align-middle">{item.resourceAmount}</td>
											<td className="d-flex gap-1 w-full justify-content-around align-items-center button_container">
												<div
													className="button_svg sucsses_boredr"
													style={{
														borderColor: "#198754",
													}}
													onClick={() => {
														Object.entries(item).forEach(([key, value]) => {
															if (
																key !== "label" &&
																key !== "value" &&
																key !== "resource"
															) {
																setValue(key, value);
															}
														});
														setIsUpdate(true);
														setDefaultValues(item);
														setOpen(true);
														setIsShowSelect(false);
													}}>
													<AiOutlineEdit size={21} color="#198754" />
												</div>
												<div
													className="button_svg error_boredr"
													onClick={() => {
														if (ids.find(el => el === item.id && loading))
															return;
														show(item.id);
													}}>
													{ids.find(el => el === item.id) && loading ? (
														<Spinner
															animation="border"
															role="status"
															size="sm"
															style={{ color: "#dc3545" }}
														/>
													) : (
														<AiOutlineDelete size={21} color="#dc3545" />
													)}
												</div>
											</td>
										</tr>
									</React.Fragment>
								);
							})}
						</tbody>
					</Table>
				</div>
			</>
		);
	}
	return null;
};

export default BadgeResources;
