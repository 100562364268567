import "./App.scss";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { authRouter, router } from "./pages/router";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { useSelector } from "react-redux";

export const TimerContext = React.createContext(null);

function App() {
	const { user } = useSelector(state => state.auth);
	const guid = window.location.pathname.split("/")[2];
	const [isUpdateTimer, setIsUpdateTimer] = useState(false);
	// const admin = true;

	// if (admin) {
	// 	return (
	// 		<>
	// 			<RouterProvider router={adminRouter} />
	// 			<ToastContainer autoClose={5000} />
	// 		</>
	// 	);
	// }

	if (!guid && user) {
		return (
			<>
				<RouterProvider router={authRouter} />
				<ToastContainer autoClose={5000} />
			</>
		);
	}
	return (
		<>
			<TimerContext.Provider value={{ isUpdateTimer, setIsUpdateTimer }}>
				<RouterProvider router={router} />
				<ToastContainer autoClose={5000} />
			</TimerContext.Provider>
		</>
	);
}

export default App;
