import { Outlet, useNavigation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { TbDeviceGamepad2 } from "react-icons/tb";
import Timer from "../timer/Timer";
import { createContext } from "react";
import { useLaader } from "../../helpers/hooks";

export const LoadingContext = createContext(null);

const Root = () => {
	const navigation = useNavigation();
	const { load, ids, setLoad, setLoading, loading } = useLaader();

	return (
		<>
			<div className="w-100 position-fixed " style={{ zIndex: 100 }}>
				<Navbar bg="primary" data-bs-theme="dark" style={{}}>
					<div className="container-xxl d-flex justify-content-between align-items-center text-white py-1 flex-wrap">
						<div className="d-flex align-items-center">
							<TbDeviceGamepad2 size={35} color="#ffffff" />
							<h4 className="text-white ms-2 mb-0">Gamification</h4>
						</div>
						{/* <Nav className="gap-3">
						<Nav.Item>
							<Link to={`/resouerces/${token}`}>Resouerces</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/quest/${token}`}>Tasks</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/badges/${token}`}>Badges</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/leaderbord/${token}`}>Leaderbord</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/shop-item/${token}`}>Shop Item</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/category/${token}`}>Category</Link>
						</Nav.Item>
					</Nav> */}
						<Timer />
					</div>
				</Navbar>
				{(navigation.state !== "idle" || loading) && (
					<div
						className="loader-line"
						style={{
							position: "fixed",
							width: "100%",
						}}></div>
				)}
			</div>

			<div style={{ paddingTop: "71px" }}></div>
			<LoadingContext.Provider
				value={{ load, ids, setLoad, setLoading, loading }}>
				<div className="container-xxl mt-3 mb-5">
					<Outlet />
				</div>
			</LoadingContext.Provider>
		</>
	);
};

export default Root;
