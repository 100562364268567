import Select from "react-select";
import { useError } from "../../helpers/hooks";
import { handleChange } from "../../helpers/helper";
import { useMemo } from "react";
// import Select from "react-select";
const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: state.isFocused
			? "86b7fe"
			: state.selectProps.error
			? "red"
			: "gray",
		boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(13,110,253,.25)" : "",
	}),
};

const CustomSelect = ({
	cb = () => {},
	isMulti,
	defaultValues,
	label,
	regName = "",
	onChange = () => {},
	options = [],
	required = false,
	disabled = false,
}) => {
	const errorMessage = useError(regName);
	const value = useMemo(() => {
		if (Array.isArray(defaultValues)) {
			return defaultValues?.map(el => ({ value: el.id, label: el.name }));
		} else {
			let label = options.find(
				el => el.value === defaultValues || el.label === defaultValues,
			);
			if (label) return label;
		}
		return defaultValues;
	}, [options, defaultValues]);
	if (isMulti) {
		return (
			<div className="form-group mb-3">
				<label className="mb-2 form-label">
					{label}
					{required ? <span className="required ms-1">*</span> : ""}
				</label>
				<Select
					isMulti
					className="select"
					defaultValue={value}
					options={options ? options : []}
					error={errorMessage}
					styles={customStyles}
					onChange={onChange}
				/>
				{!!errorMessage && <span className="helper_text">{errorMessage}</span>}
			</div>
		);
	}
	return (
		<div className="form-group mb-3">
			<label className="mb-2 form-label">
				{label}
				{required ? <span className="required ms-1">*</span> : ""}
			</label>
			<Select
				className="select"
				defaultValue={value}
				isDisabled={disabled}
				options={options ? options : []}
				error={errorMessage}
				styles={customStyles}
				onChange={e => {
					if (regName) handleChange(regName, e.value, cb);
					onChange(e.value, {
						shouldValidate: true,
						shouldDirty: true,
					});
				}}
			/>
			{!!errorMessage && <span className="helper_text">{errorMessage}</span>}
		</div>
	);
};
export default CustomSelect;
