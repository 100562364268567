import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppSubmit } from "../../helpers/hooks";
import {
	Link,
	useActionData,
	useFormAction,
	useNavigate,
} from "react-router-dom";
import { Card, Form } from "react-bootstrap";
import CustomsInput from "../../components/Inputs/CustomsInput";
import ActionButton from "../../components/butons/ActionButton";
import { singInValidation } from "../../helpers/validation";
import ApiService from "../../helpers/api";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/reducers/AuthReducer";

function Component(props) {
	const submit = useAppSubmit(),
		action = useFormAction();
	const actiondata = useActionData();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const formMethods = useForm({
		defaultValues: { username: "", password: "" },
		resolver: yupResolver(singInValidation),
	});
	const { handleSubmit } = formMethods;
	useEffect(() => {
		if (actiondata?.data) {
			navigate("/");
			dispatch(setUser(actiondata?.data));
		}
	}, [actiondata, navigate, dispatch]);
	const onSubmit = data => {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach((el, i) => {
					formData.append(key, el);
				});
			} else formData.append(key, value);
		});
		submit(formData, { method: "POST", action });
	};
	return (
		<div className="sign_container">
			<Card className="p-4 coustm_card d-flex gap-4 flex-column justify-content-center rounded">
				<h1 className="text-center fs-1">Sign in</h1>
				<FormProvider {...formMethods}>
					<Form
						noValidate={true}
						onSubmit={handleSubmit(onSubmit)}
						className="">
						<CustomsInput
							regName={"username"}
							type="text"
							required={true}
							label={"Email"}
							placeholder={"Name"}
						/>
						<CustomsInput
							regName={"password"}
							type="password"
							required={true}
							label={"Password"}
							placeholder={"Password"}
						/>
						<Link to="forgot-password" className="link text-end d-block">
							Forgot password?
						</Link>
						<ActionButton
							variant="outline-primary"
							buttonClassName="w-100 mt-4 "
							buttonName="Sign in"
						/>
					</Form>

					<div className="mb-0 text-center">
						<hr />
						Don't have an account?{" "}
						<Link to="sing-up" className="fw-bold link">
							Sign Up
						</Link>
					</div>
				</FormProvider>
			</Card>
		</div>
	);
}
const action = async ({ request, params }) => {
	try {
		const formData = await request.formData();
		const data = await ApiService.partner_loginIdentity(formData);
		localStorage.setItem("token", data.data.accessToken);
		localStorage.setItem("refreshToken", data.data.accessToken);
		const userData = await ApiService.identity_getPWDAccessToken();
		localStorage.setItem("publicKey", userData.data.publicKey);
		return userData;
	} catch (error) {
		return error;
	}
};

const SignIn = Object.assign(Component, { action });

export default SignIn;
